<template>
  <div>
    <b-img
      v-if="skin !== 'dark'"
      width="210px"
      src="@/assets/images/logo-text-black.png"
    />
    <b-img
      v-else
      width="210px"
      src="@/assets/images/logo-text-white.png"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: { BImg },
  computed: {
    skin () {
      return store.state.appConfig.layout.skin
    }
  }
}
</script>
